/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
*******************************/
$side-page-width: 100%;
.modal {
  z-index: 1050;
}

.app-modal-left,
.app-modal-right {
  &.modal {
    z-index: 1050;
    & .modal-dialog {
      position: fixed;
      margin: auto;
      width: $side-page-width;
      height: 100%;
      transform: translate3d(0%, 0, 0);
    }

    & .modal-content {
      height: 100%;
      overflow-y: auto;
    }

    & .modal-body {
      padding: 15px 15px 80px;
      overflow-y: auto;
      &.set-min-height-modal {
        min-height: 80vh;
      }
    }
  }
}

.app-modal-max-height {
  & .modal-body {
    max-height: 80vh;
    overflow: auto;
  }
}

/* Right */
.app-modal-right {
  &.modal {
    & .modal-dialog {
      right: 0;
    }
  }
}
