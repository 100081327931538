@import 'app-vars';
@import 'app-colors';

$app-page-m: 2rem;

.app-page-header {
  height: $logo-height;
  margin-bottom: $app-page-m;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  .app-page-title {
    margin-bottom: 0;
    font-size: 1.75rem;
    font-weight: 500;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
    min-height: 4rem;
    justify-content: center;

    & .btn-group {
      padding-bottom: 0.5rem;
    }
  }
}

.link-styles {
  text-decoration: underline;
  cursor: pointer;
}

.word-break {
  word-break: break-word;
}

.app-cursor-pointer {
  cursor: pointer;
}

.app-tabs-menu {
  & .nav-link {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border: 1px solid $c-app-border;
    background: transparent;
    padding: 25px;
    cursor: pointer;
    &.active {
      background: $c-app-light;
    }
  }
}

.app-item-search-results-wrapper {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $c-app-border;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  max-width: 400px;
  width: 100%;
  margin: auto;
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow: auto;

  p {
    padding: 1rem;
    padding-bottom: 0;
  }
}

.app-item-search-results {
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid $c-app-border;
  text-transform: capitalize;
  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background: #f7f7f7;
  }
}

.app-d-contents {
  display: contents !important;
}

.app-page-filter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  & .app-filter-btn-wrapper {
    margin-left: auto;
  }
  @include media-breakpoint-down(md) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    & .app-filter-btn-wrapper {
      margin: 0;
    }

    & app-search-input,
    & select {
      width: 100%;
      margin-bottom: 0.8em;
    }
  }
}

.card {
  border: 0;
  & .card-body {
    border: 0;
  }
}

.btn-group,
.btn-group-vertical {
  border-radius: 8px;
}

.btn {
  border-radius: 8px;
  padding: 1.25rem;
  font-size: 0.9rem;
  line-height: 1rem;
  padding: 1.25rem;
  font-size: 0.9rem;
  line-height: 1rem;
  min-width: 100px;
  box-shadow: none;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.app-dropdown-outline {
  background: #ffffff;
  border: 1px solid #e8e8e8;
}

.app-category-search {
  & .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow: auto;
  }
}

.label-danger {
  background: $danger;
  border-radius: 0.25rem;
  display: inline-flex;
  color: $c-app-light;
  text-transform: uppercase;
  font-size: 0.65rem;
  padding: 0.7rem;
  font-weight: 600;
}

.app-button-circle.btn {
  border-radius: 50% !important;
}

.app-icon-button.btn {
  min-width: auto;
  width: 51px;
  height: 51px;
  font-size: 1rem;
  padding: 1rem;
  margin: 0 0.5rem;
  &.app-icon-button-small {
    width: 45px;
    height: 45px;
  }
  &::after {
    display: none !important;
  }
}
.dropdown {
  background: transparent !important;
}

.overflow-visible {
  overflow: visible !important;
}

.app-clear-icon {
  &:before {
    content: '\02715';
    font-style: normal;
    font-size: 1.1rem;
  }
}

.btn {
  min-width: auto;
}

.select-full-width {
  select {
    min-width: 100%;
  }
}

.form-switch .form-check-input {
  border-radius: 2em;
  width: 3rem;
  height: 1.5rem;
}

.form-check {
  padding-left: 2.5rem;
  & .form-check-input {
    margin-left: -2.5rem;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
  }
  & .form-check-label {
    line-height: 2.5rem;
  }
}

.form-check-label {
  line-height: 2rem;
}

.leaflet-bottom {
  display: none;
}

.accent-block {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
}

.accordion {
  & .card-header {
    border-bottom: 0;
    & > .btn {
      width: 100%;
      text-align: left;
      text-decoration: none;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      &:focus {
        box-shadow: none;
      }
      &::after {
        content: '';
        margin: 0 0.5em;
        display: inline-block;
        border: 7px solid transparent;
        border-top: 8px solid gray;
        border-bottom: 0 none;
        transform: rotate(180deg);
        transition: transform 0.1s linear;
      }
      &.collapsed {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
}

.led-box {
  height: 30px;
  width: 25%;
  margin: 10px 0;
  float: left;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 1em;
}

.led-red {
  width: 18px;
  height: 18px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
}

.led-yellow {
  width: 18px;
  height: 18px;
  background-color: #ff0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #ff0 0 2px 12px;
}

.led-green {
  width: 18px;
  height: 18px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;
}

.led-blue {
  width: 18px;
  height: 18px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 14px;
}
