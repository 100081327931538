/**
 * The file contains common styles for all elements in the navbar.
 * It is not in the menu's custom component because the styles should be applied
 * to components that are included in the menu itself, and due to the shadow DOM
 * the styles wont be propagated.
 */

@import 'app-vars';
@import 'app-colors';

.app-navbar {
  margin-top: 2em;
  padding: 0;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  & .navbar-nav {
    & .nav-item {
      & .nav-link {
        padding: 1em 1rem 1em 0;

        &:hover {
          background-color: darken($primary, 5%);
          border-radius: 0;
        }

        &.router-link-active {
          background-color: $secondary;
          color: $c-app-light;
          border-radius: 0;
          &:focus {
            box-shadow: none;
          }
        }
      }

      & .app-sub-menu-opened {
        background-color: darken($primary, 5%);
      }
      & .app-sub-menu {
        background-color: darken($primary, 3%);

        & .nav-link {
          padding-left: 1rem;
        }
      }
    }
  }
}

.app-button-center {
  display: flex;
  align-items: center;
  height: 100%;
}

.app-nav-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 100%;
}

.app-user-settings {
  margin-top: auto;

  & .dropdown-toggle {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 4em;
    min-width: 4rem;
    padding-left: 0;
    padding-right: 0;
    &::after {
      display: none;
    }

    & .app-user-settings-creds {
      display: flex;
      flex-flow: column nowrap;
      text-align: left;
    }
  }

  & .dropdown-menu {
    background-color: darken($primary, 3%);
    border-top: 2px solid $secondary;
    top: -80px;
    & .dropdown-item {
      color: $c-app-light;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($primary, 8%);
      }

      & i {
        width: 2rem;
      }
    }
  }
}
