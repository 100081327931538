@import 'app-colors';

// Custom input's borders on focus
textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='file']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  outline: none;
  border: 1px solid $secondary;
  box-shadow: inset 0 0 0 1px $secondary;
}

.app-error {
  input,
  textarea,
  textarea:focus,
  select,
  select:focus,
  input[type='text']:focus,
  input[type='password']:focus,
  input[type='file']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='time']:focus,
  input[type='week']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='color']:focus {
    border: 1px solid $danger;
    box-shadow: inset 0 0 0 1px $danger;
  }
}

.app-password-input {
  position: relative;
  & > i {
    position: absolute;
    top: 5px;
    right: 1px;
    padding: 1rem;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.app-input-with-clear {
  position: relative;
  & .app-clear-input-btn {
    &:active,
    &:focus {
      box-shadow: none;
    }
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.4;
  }
}

.form-group {
  margin-bottom: 1rem;
  & small {
    display: none;
  }

  &.app-error {
    & small {
      display: block;
    }
  }
  &.app-password-error {
    &.app-error {
      & .app-password-error-text {
        display: none;
      }
      & small {
        display: block;
      }
    }
    & input {
      border: 1px solid $danger;
      box-shadow: inset 0 0 0 1px $danger;
    }
    & .app-password-error-text {
      display: block;
      font-size: 80%;
    }
    & small {
      display: none;
    }
  }

  & .app-password-error-text {
    display: none;
  }
}

.form-group {
  & small.text-danger {
    display: none;
  }

  &.app-error {
    position: relative;
    & small.text-danger {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
    }
    &.app-no-label-input {
      & small.text-danger {
        right: 5px;
      }
    }
  }

  &.app-password-error {
    &.app-error {
      & .app-error-span {
        display: none;
      }
      & small.text-danger {
        display: block;
      }
    }
    & input {
      border: 1px solid $danger;
      box-shadow: inset 0 0 0 1px $danger;
    }
    & .app-error-span {
      display: block;
      font-size: 80%;
    }
    & small {
      display: none;
    }
  }

  & .app-error-span {
    display: none;
  }
}

.app-filter-select {
  max-width: 16em;
  width: auto;
}

input[type='text'].lower-case {
  text-transform: lowercase;
}
.lower-case::-webkit-input-placeholder {
  text-transform: none;
}
.lower-case:-moz-placeholder {
  text-transform: none;
}
.lower-case::-moz-placeholder {
  text-transform: none;
}
.lower-case:-ms-input-placeholder {
  text-transform: none;
}
.lower-case::placeholder {
  text-transform: none;
}

.form-control {
  border: 1px solid $c-app-border;
  box-shadow: none;
  border-radius: 8px;
  padding: 14px 20px;
  &:read-only {
    background: #f7f7f7;
  }
}

select {
  max-width: 10em !important;
  &.form-control:read-only {
    border: 1px solid $c-app-border;
    background: #fff;
  }

  &.form-control {
    height: auto;
    padding: 15px;
  }
}
.app-error {
  select {
    &.form-control:read-only {
      border-color: $danger;
    }
  }
}

.custom-control-label {
  &:before {
    width: 25px;
    height: 25px;
    border-radius: 5px;
  }
  &:after {
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 0;
    display: block;
    content: '';
  }
}

.custom-control {
  margin: 20px 0;
  .custom-control-label {
    display: block;
    position: relative;
    padding-top: 5px;
    padding-left: 15px;
  }
}
