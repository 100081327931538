$c-app-primary: #2B2B2B;
$c-app-accent: #21BEF4;
$c-app-success: #76c000;
$c-app-light: #ffffff;
$c-app-dark-light: #f7f7f7;
$c-app-dark: darken($c-app-primary, 10%);
$c-app-warn: #dc3545;
$c-app-info: #c9f0ff;
$c-app-divider: #d4d4d4;
$c-app-table-header: #a8a8a8;
$c-app-border: #e8e8e8;

// Bootstrap customization
$primary: $c-app-primary;
$secondary: $c-app-accent;
$success: $c-app-success;
$danger: $c-app-warn;
$info: $c-app-info;
$dark: $c-app-dark;
$enable-rounded: false;
$enable-shadows: true;
