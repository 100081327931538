@import 'app-colors';

.card-body {
  overflow: auto;
}

.app-table-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($light, 0.3);
  top: 0;
  left: 0;
  z-index: 10;
}

table {
  &.table {
    margin-bottom: 0;

    & .app-dropdown-cell {
      padding: 0 50px;
    }
    & .app-action-column {
      width: 14rem;
    }

    & tr:hover {
      & .dropdown {
        background: #fafafa;
        z-index: 1;
      }
    }

    th {
      background: $c-app-table-header;
      color: $c-app-light;
      border: none;
      font-weight: normal;
      padding: 25px 10px;
      &:first-child {
        padding-left: 25px;
      }
    }
    span {
      td:first-child {
        padding-left: 10px;
      }
    }
    td {
      padding: 2rem;
      &:first-child {
        padding-left: 1.5rem;
      }
    }
    th {
      padding: 2rem;
      &:first-child {
        padding-left: 1.5rem;
      }
    }
  }
  & tbody {
    background: $c-app-light;
  }
  & thead {
    & tr {
      border: none;
    }

    & th {
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  & tr {
    & td {
      & .btn {
        margin-right: 0.4rem;
      }
      & .dropdown-menu {
        & .dropdown-item {
          & i {
            width: 1.6rem;
          }
        }
      }
      & .badge {
        padding: 0.4em;
        line-height: 1rem;
        min-width: 1.5rem;
      }
    }
  }

  &.app-2d-table {
    & tr {
      & td {
        &:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
}

.app-table-inner-table {
  table {
    &.table {
      th {
        padding: 1rem;
      }
    }
    & thead {
      & th {
        &:first-child {
          border-top-left-radius: 0;
        }
        &:last-child {
          border-top-right-radius: 0;
        }
      }
    }
  }
}

.app-no-items-found {
  padding: 3em 1em;
  background-color: #fff;
  text-align: center;
}

.app-mobile-table-single-item {
  padding: 1.5rem;
  border-bottom: 1px solid $c-app-border;
  & p:last-child {
    margin-bottom: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  & .app-mobile-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
