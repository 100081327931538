@import 'app-colors';

.cdk-drag {
  cursor: move;
}

tr {
  &.cdk-drag-preview {
    background: $c-app-light;
    padding: 1em;
    button,
    a {
      display: none;
    }
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.app-drag-drop-placeholder {
  background: darken($c-app-light, 4%);
  border: dashed 2px $secondary;
  min-height: 4em;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.app-drag-drop-list {
  &.cdk-drop-list-dragging {
    & .example-box {
      &:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
}
