@import 'app-vars';
@import 'app-colors';

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap';

@import 'app-scrollbar';
@import 'app-alerts';
@import 'app-tables';
@import 'app-pages';
@import 'app-modal-pages';
@import 'app-datepicker';
@import 'app-navbar';
@import 'app-forms';
@import 'app-drag-drop';
@import 'app-profile-pic';

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

html,
body {
  background: $c-app-dark-light;
  font-size: 14px;
  overflow-x: hidden;
  height: 100%;
  font-family: 'Montserrat';
}
