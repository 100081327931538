.app-profile-pic {
  height: 100%;
  min-height: 15.5em;
  max-height: 15.5em;
  width: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  & i {
    align-self: center;
    font-size: 12em;
  }
}
